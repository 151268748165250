@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 100;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-100.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 100;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-100italic.woff2')
		format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 200;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-200.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 200;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-200italic.woff2')
		format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 300;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-300.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 300;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-300italic.woff2')
		format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-regular.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 400;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-italic.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 500;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-500.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 500;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-500italic.woff2')
		format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 600;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-600.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 600;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-600italic.woff2')
		format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-700.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 700;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-700italic.woff2')
		format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 800;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-800.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 800;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-800italic.woff2')
		format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 900;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-900.woff2') format('woff2');
}
@font-face {
	font-display: swap;
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 900;
	src: url('/fonts/noto-sans/noto-sans-v28-latin-900italic.woff2')
		format('woff2');
}
/* Font metric overrides reduces CLS from font-swap.
This is manually inserted, metric generated using CLI commands:
npx fontpie ./public/fonts/noto-sans/noto-sans-v28-latin-200italic.woff2 --weight 200 --style normal
npx fontpie ./public/fonts/noto-sans/noto-sans-v28-latin-300italic.woff2 --weight 300 --style italic
etc...
*/
@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 100;
	src: local('Arial');
	ascent-override: 108.84%;
	descent-override: 29.83%;
	line-gap-override: 0%;
	size-adjust: 98.22%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 100;
	src: local('Arial Italic');
	ascent-override: 111.94%;
	descent-override: 30.68%;
	line-gap-override: 0%;
	size-adjust: 95.5%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 200;
	src: local('Arial');
	ascent-override: 107%;
	descent-override: 29.33%;
	line-gap-override: 0%;
	size-adjust: 99.91%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 200;
	src: local('Arial Italic');
	ascent-override: 110.8%;
	descent-override: 30.37%;
	line-gap-override: 0%;
	size-adjust: 96.48%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 300;
	src: local('Arial');
	ascent-override: 104.42%;
	descent-override: 28.62%;
	line-gap-override: 0%;
	size-adjust: 102.37%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 300;
	src: local('Arial Italic');
	ascent-override: 109.14%;
	descent-override: 29.91%;
	line-gap-override: 0%;
	size-adjust: 97.95%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 400;
	src: local('Arial');
	ascent-override: 100.41%;
	descent-override: 27.52%;
	line-gap-override: 0%;
	size-adjust: 106.47%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 400;
	src: local('Arial Italic');
	ascent-override: 106.59%;
	descent-override: 29.21%;
	line-gap-override: 0%;
	size-adjust: 100.3%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 500;
	src: local('Arial');
	ascent-override: 98.41%;
	descent-override: 26.97%;
	line-gap-override: 0%;
	size-adjust: 108.62%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 500;
	src: local('Arial Italic');
	ascent-override: 104.43%;
	descent-override: 28.62%;
	line-gap-override: 0%;
	size-adjust: 102.36%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 600;
	src: local('Arial Bold');
	ascent-override: 104.19%;
	descent-override: 28.56%;
	line-gap-override: 0%;
	size-adjust: 102.6%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 600;
	src: local('Arial Bold Italic');
	ascent-override: 110.5%;
	descent-override: 30.29%;
	line-gap-override: 0%;
	size-adjust: 96.74%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 700;
	src: local('Arial Bold');
	ascent-override: 101.67%;
	descent-override: 27.87%;
	line-gap-override: 0%;
	size-adjust: 105.14%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 700;
	src: local('Arial Bold Italic');
	ascent-override: 107.76%;
	descent-override: 29.54%;
	line-gap-override: 0%;
	size-adjust: 99.2%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 800;
	src: local('Arial Bold');
	ascent-override: 99.98%;
	descent-override: 27.4%;
	line-gap-override: 0%;
	size-adjust: 106.92%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 800;
	src: local('Arial Bold Italic');
	ascent-override: 105.71%;
	descent-override: 28.98%;
	line-gap-override: 0%;
	size-adjust: 101.12%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: normal;
	font-weight: 900;
	src: local('Arial Bold');
	ascent-override: 98.08%;
	descent-override: 26.88%;
	line-gap-override: 0%;
	size-adjust: 109%;
}

@font-face {
	font-family: 'Noto Sans Fallback';
	font-style: italic;
	font-weight: 900;
	src: local('Arial Bold Italic');
	ascent-override: 103.42%;
	descent-override: 28.35%;
	line-gap-override: 0%;
	size-adjust: 103.36%;
}
